<template>
  <div class="margin">
    <br />
    <Button v-if="id" _key="btnRentCreate" title="Locação" classIcon="fas fa-plus-circle" type="primary" size="small"
      eventName="rentCreate" :clicked="newRent" />
    <DropdownOptionsRentOperational v-if="id" :rent="rent" :periodRentId="periodRentId"
      :statusMovimentStock="statusMovimentStock" />
    <div v-if="id">
      <br />
      <b-row>
        <b-col sm="5">
          <Badge :title="'Nº' + rent.number.toString()" type="primary" size="medium" />
          <Badge :title="statusRental.title" :type="statusRental.type" size="small" />
        </b-col></b-row>
      <br />
    </div>
    <Molded>
      <b-row>
        <b-col sm="12">
          <InputText title="Identificação" field="name" :formName="formName" :required="false" :maxLength="100"
            v-model="rent.identification" />
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="8">
          <CustomerWithCreate :formName="formName" v-model="rent.customer" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="4">
          <Select title="Contato" field="type" :formName="formName" :required="true" :disabled="!rent.customer.id"
            url="/api/v1/shared/contact/select-all" :propsParams="{ any: rent.customer.id }" v-model="rent.contact"
            nameNewRegister="contactCreateUpdate" :showNewRegister="hasRule('CRM')" titleNewRegister="Contato"
            :widthNewRegister="750" :heightNewRegister="250">
            <ContactCreateUpdate :registerInSelect="true" :customerSupplierId="rent.customer.id"
              v-model="rent.contact" />
          </Select>
        </b-col>
      </b-row>
      <br />
    </Molded>
    <div v-if="!id">
      <br />
      <Molded>
        <div class="div-period">
          <PeriodRentCreateUpdate :showBtnSave="false" v-model="rent.periodRent" />
        </div>
      </Molded>
    </div>
    <div v-if="rent.id">
      <br />
      <Tab v-model="tabIndex" :initialTabs="tabsByModule">
        <div :slot="0">
          <div class="div-period-create-update">
            <PeriodRentCreateUpdate :showBtnSave="false" :markFormDirty="true" :showGenerate="false"
              :showModeGenerate="false" v-model="rent.periodRent" />
          </div>
        </div>
        <div :slot="1">
          <ItemsRentOperationalList v-if="tabIndex == 1" :rent="rent" :periodRentId="periodRentId" />
        </div>
        <div :slot="2">
          <AddressRentList v-if="tabIndex == 2" :rent="rent" />
        </div>
        <div :slot="3">
          <b-row>
            <b-col sm="12">
              <EditorHtml title="Observações da Locação" field="observationRental" :required="false" :maxLength="3000"
                v-model="rent.observationRental" />
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <EditorHtml title="Observações da OS" field="observationServiceOrder" :required="false" :maxLength="3000"
                v-model="rent.observationServiceOrder" />
            </b-col>
          </b-row>
        </div>
        <div :slot="4">
          <TeamList :rent="rent" />
        </div>
        <div :slot="5">
          <ItemsOsList :rent="rent" />
        </div>
        <div :slot="6">
          <MovimentList :rent="rent" />
        </div>
        <div :slot="7">
          <br>
          <FileManager :showInModal="true" source="rent" :genericId="id" />
        </div>
        <div :slot="8">
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="2" xl="2">
              Data Cadastro <br>
              <i class="fa-solid fa-calendar"></i> {{ rent.registeredDate }}
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="2" xl="2">
              Usuário Cadastro <br>
              <i class="fa-solid fa-user"></i> {{ rent.registeredUser }}
            </b-col>
          </b-row>
          <div class="div-date-accepted">
            <b-row>
              <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                <DateTime title="Data de Efetivação" field="dateAccepted" format="DD/MM/YYYY" type="date" placeholder
                  :formName="formName" :required="false" v-model="rent.dateAccepted" />
              </b-col>
            </b-row>
          </div>
        </div>
      </Tab>
    </div>
  </div>
</template>
<script>


import Badge from "@nixweb/nixloc-ui/src/component/layout/Badge.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import Tab from "@nixweb/nixloc-ui/src/component/layout/Tab.vue";
import TextArea from "@nixweb/nixloc-ui/src/component/forms/TextArea";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import FileManager from "@nixweb/nixloc-ui/src/component/shared/file-manager/FileManager";

import DropdownOptionsRentOperational from './DropdownOptionsRentOperational.vue'
import ItemsRentOperationalList from '../items-rent/ItemsRentOperationalList.vue'
import PeriodRentCreateUpdate from '../../rental/period-rental/PeriodRentCreateUpdate.vue'
import ContactCreateUpdate from '../../shared/contact/ContactCreateUpdate.vue'
import CustomerWithCreate from '../../rental/shared/CustomerWithCreate.vue'
import AddressRentList from '../../rental/address-rent/AddressRentList.vue'
import MovimentList from '../../rental/moviment/MovimentList.vue'
import TeamList from '../../rental/team/TeamList.vue'
import ItemsOsList from '../../rental/maintenance/ItemsOsList.vue'

import Rent from "@/components/modules/operational/rent/RentOperational.js";

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "RentOperationalCreateUpdate",
  components: {
    Badge,
    Select,
    RadioGroup,
    TextArea,
    Molded,
    Tab,
    InputText,
    Modal,
    DateTime,
    Button,
    EditorHtml,
    CustomerWithCreate,
    ContactCreateUpdate,
    PeriodRentCreateUpdate,
    AddressRentList,
    MovimentList,
    ItemsRentOperationalList,
    DropdownOptionsRentOperational, TeamList, FileManager, ItemsOsList
  },
  data() {
    return {
      id: this.$route.params.id,
      periodRentId: this.$route.params.periodRentId,
      formName: "rentOperationalCreateUpdate",
      rent: new Rent(),
      urlCreate: "/api/v1/operational/rent/create",
      urlUpdate: "/api/v1/operational/rent/update",
      urlGetById: "/api/v1/operational/rent/get-by-id",
      urlItemsMovimentTotalization: "/api/v1/stock/items-moviment/totalization",
      statusMovimentStock: {},
      customerAndContact: {},
      tabIndex: 0,
      tabs: [
        { index: 0, title: "Período" },
        { index: 1, title: "Produto" },
        { index: 2, title: "Saída/Retorno" },
        { index: 3, title: "Observações" },
        { index: 4, title: "Equipe" },
        { index: 5, title: "Manutenção" },
        { index: 6, title: "Movimentação" },
        { index: 7, title: "Anexo" },
        { index: 8, title: "Info" },
      ],
    };
  },
  created() {
    if (this.id) {
      this.getById();
      this.getStatusMovimentStock();
      this.tabIndex = 1;
    }
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "showModal", "isLoading"]),
    ...mapGetters("user", ["hasRule"]),
    statusRental() {
      if (this.rent.statusRental == 1)
        return { title: "Orçamento", type: "info" };

      if (this.rent.statusRental == 2)
        return { title: "Aprovada", type: "success" };

      if (this.rent.statusRental == 3)
        return { title: "Reprovada", type: "danger" };
    },
    tabsByModule() {
      let tabs = [];
      var isOperational = this.hasRule("Operational");
      var isMaintenance = this.hasRule("Maintenance");

      this.tabs.forEach((tab) => {
        if (tab.title == "Manutenção" && isMaintenance) tabs.push(tab);
        if (tab.title == "Movimentação" && isOperational) tabs.push(tab);
        if (tab.title != "Manutenção" && tab.title != "Movimentação")
          tabs.push(tab);
      });
      return tabs;
    },
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    ...mapMutations("generic", [
      "addEvent",
      "removeLoading",
      "openModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation", "removeFormDirty"]),
    create() {
      let params = { url: this.urlCreate, obj: this.rent };
      this.postApi(params).then((response) => {
        if (response.success)
          this.$router.replace({
            name: "rentOperationalUpdate",
            params: { id: response.content.id, periodRentId: response.content.periodRent.id },
          });
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    update() {
      let params = { url: this.urlUpdate, obj: this.rent };
      this.putApi(params).then(() => {
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    getById() {
      let params = { url: this.urlGetById, obj: { id: this.id, any: this.periodRentId } };
      this.getApi(params).then((response) => {
        this.rent.update(response.content);
        let self = this;
        setTimeout(function () {
          self.removeLoading(["panel"]);
          self.removeFormDirty();
        }, 100);
      });
    },
    getStatusMovimentStock() {
      let params = {
        url: this.urlItemsMovimentTotalization,
        obj: { rentId: this.id, periodRentId: this.periodRentId },
      };
      this.getApi(params).then((response) => {
        this.statusMovimentStock = response.content;
      });
    },
    newRent() {
      let self = this;
      setTimeout(function () {
        self.$router.push({
          name: "rentOperationalCreate",
        });
        self.removeLoading(["btnRentCreate"]);
      }, 200);
    },
  },
  watch: {
    event: {
      handler(event) {
        let name = event.name;

        if (name == "saveSaveCancel") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }

        if (name == "periodRentCreateUpdate" || name == "periodRentUpdate") {
          this.openModal("periodRentCreateUpdate");
        }

        if (name == "generatePeriodRent") this.rent.periodRent = event.data;

        if (name == "addedProduct" || name == "removedItemRent") {
          this.getStatusMovimentStock();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.margin {
  margin-right: 10px;
}

.div-period {
  margin: auto;
  width: 80%;
  padding: 10px;
}

.div-period-create-update {
  max-width: 900px;
}


.div-date-accepted {
  margin-top: 10px;
}
</style>
